import React from 'react'
import { Link } from 'react-router-dom'
import Aboutpage from './aboutpage'

const First = () => {
  return (
    <div className='first-container'>
        <div className='para'>
    <h1><span className='branch'>SADE TECHNO SOLUTIONS</span></h1>
    <p className='section-desc'><span className='branch-container'>Company that does<br/> great work</span></p>
    <p className='section-con'><span className='white-contain'>We are a fast-growing IT company in India<br/> providing one-stop IT solutions and services<br/> with a professional
    approach</span></p>
    
    <Link style={{textDecoration:'none' }} to='/about' ><div className='section-con'><button className= "video">Learn More</button></div> </Link>
  
    </div>
    </div>
    
    
  )
}

export default First