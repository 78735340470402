import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { NavLink } from 'react-router-dom';
import { Icon } from '@iconify/react/dist/iconify.js';

const Swiper=()=>{
    const renderNextButton = ({ isDisabled, onClick }) => (
        <button className='render'
            onClick={onClick}
            disabled={isDisabled}>
    <Icon className='icon-all-container'icon="material-symbols:arrow-forward-ios" />   
    </button>
    );
    const renderPrevButton= ({ isDisabled, onClick }) => (
        <button className='render-button'
        onClick={onClick}
        disabled={isDisabled}>
<Icon  className='icon-all-container'icon="ic:sharp-arrow-back-ios" /> 
</button>
);

    // const items =[
        //     <div className='repet'>
        // <div className='ones'>
        //     <img src={data.img}/>
        //     <h3>{data.Name}</h3>
        //    <p>{data.content}</p>
        //     </div>
                {/* <div className='ones'>
                    <h3><span className='branch'>IT Consultancy</span></h3>
                    <p>Revamp Your Business with Smart<br/> IT Strategies from Sade Tech.</p>
                </div>
           
                <div className='ones'>
                    <h3><span className='branch'>QA & Testing</span></h3>
                    <p>Our Sade techno services are designed to <br/>ensure that software and business<br/> projects attain superior standards of<br/> performance,
                    reliability, and user satisfaction.</p>
                </div> */}
                // </div>,
                // <div className='repet' >
        
                // <div className='ones'>
                //     <h3><span className='branch'>UI & UX Design Services</span></h3>
                //     <p>Elevate your software products with <br/>cutting-edge UI/UX design services <br/>that enhance user engagement<br/>and
                //     boost revenue.</p>
                // </div>
                //     <div className='ones'>
                //         <h3> <span className='branch'>Mobile App Development</span></h3>
                //         <p>Explore the ever-evolving realm <br/>of mobile technology through<br/> Sade Techno's Mobile App
                //         Development<br/> Services.</p>
                //     </div>
                
                //     <div className='ones'>
                //         <h3><span className='branch'>Web Application Development</span></h3>
                //         <p>At Sade techno solutions, we help<br/> companies evolve into dynamic,<br/> forward-thinking organizations<br/> that thrive
                //         in an ever-changing <br/>landscape. </p>
                //     </div>
                //     </div>,
                //     <div className='repet'>
                //     <div className='ones'>
                //         <h3><span className='branch'>SaaS Product Development</span></h3>
                //         <p>Using an end-to-end approach to <br/>developing products, we can<br/> build an end-to-end SaaS solution <br/>that includes
                //         web apps, APIs, <br/> storage capabilities, and <br/>data analytics.</p>
                //     </div>
                
                //     <div class='ones'>
                //         <h3><span className='branch'>Digital Marketing</span></h3>
                //         <p>Transform your brand's online<br/>presence with Sade techno's<br/> innovative digital marketing services</p>
                //     </div>
            
                
                //     <div className='ones'>
                //         <h3><span className='branch'>IT Security Services</span></h3>
                //         <p>Our comprehensive IT security and <br/>disaster recovery services are <br/>tailor-made to safeguard your <br/>crucial IT environment
                //         against <br/>potential threats.</p>
                //     </div>
                    // </div>
                    // ];
                    const data=[{id:1,Name:'IT Consultancy',content:'Revamp Your Business with Smart IT Strategies from Sade Tech.At Sade Tech, we have years of industry experience and can help propel your business by discovering scaling opportunities.We specialize in everything from IT infrastructure.'},
                        {id:2,Name:'QA & Testing',img:'back.jpeg',content:'Our Sade techno services are designed to ensure that software and business projects attain superior standards of performance,reliability, and user satisfaction.We provide businesses with expert guidance and support to enable them to enhance the quality .'},
                        {id:3,Name:'UX/UI Design',img:'back.jpeg',content:`Elevate your software products with cutting-edge UI/UX design services that enhance user engagement and boost revenue.Our skilled team at a leading UI/UX Design Company delivers stunning designs that drive growth and maximize customer satisfaction.`},
                        {id:4,Name:'Mobile App Development',img:'back.jpeg',content:'Explore the ever-evolving realm of mobile technology through Sade Techno Mobile App Development Services.Our expertise lies in creating bespoke mobile applications that deliver a seamless user experience coupled with powerful functionality. '},
                        {id:5,Name:'Web Application Development',img:'back.jpeg',content:`At Sade techno solutions, we help companies evolve into dynamic, forward-thinking organizations that thrivein an ever-changing landscape.We dig deep, gain valuable insights, and have the courage to take decisive action. `},
                        {id:6,Name:'SaaS Product Development',img:'back.jpeg',content:`Using an end-to-end approach to developing products, we can build an end-to-end SaaS solution that includes web apps, APIs,storage capabilities, and data analytics In order to meet the complex business requirements. `},
                        {id:7,Name:'Digital Marketing',img:'back.jpeg',content:`Transform your brand online presence with Sade techno innovative digital marketing services Through the development of captivating storylines and purposeful strategies, we take your brand and position it in a distinctive way within the vast digital world.` },
                        {id:8,Name:'IT Security Services',img:'back.jpeg',content:'Our comprehensive IT security and disaster recovery services are tailor-made to safeguard your crucial IT environmentagainst potential threats. Our team of experts can conduct vulnerability assessments and provide ongoing security support to ensure. '}
                      ] 
                      const responsive = {
                        0: { items: 1},
                        600: { items: 2 },
                        1024: { items: 4 },
                      };
      return (
        
    <div>
           <div className='color-change'> <h1>WHO WE ARE</h1> </div>
            <div className='slider-bg'>
            <h3 style={{color:'white'}}>We deal with the aspects of<span className='pro'>Professional IT Services</span></h3>
            <div className='repet'>
        <AliceCarousel renderPrevButton={renderPrevButton} renderNextButton={renderNextButton} autoPlay disableDotsControls={true} responsive={responsive} autoPlayInterval={3000} infinite mouseTracking>
    
            {data.map((service)=>(
                   <div key={service.id} className='ones'>
         <NavLink style={{textDecoration:'none',color:'inherit'}} to={`/service/${service.id}`}>
         <div className='head-container'>
         <h3 style={{textDecoration:'none',color:'inherit'}}>{service.Name}</h3>
         </div>
         <div className='map.container'>
        <p style={{textDecoration:'none',color:'inherit',marginTop:'-15px'}}>{service.content}</p>
        </div></NavLink>
        </div>
            ))}
       
        </AliceCarousel>
        </div>
        </div>
        </div>
        
          
  );
  }  

  export default Swiper;
