import React from 'react'
import { Icon } from '@iconify/react/dist/iconify.js';
import About from './about';
import { Link } from 'react-router-dom';
import Contact from './contact';
import Service from './service';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate =useNavigate()
    const homeopen =()=>{
        navigate('/');
        window.scrollTo(0,0);
    };
    const aboutopen = () => {
        navigate('/about');
        window.scrollTo(0, 0);
    };
    const serviceopen = () => {
        navigate('/service');
        window.scrollTo(0, 0); // Scroll to top after navigation
      };
    
      const contactopen = () => {
        navigate('/contact');
        window.scrollTo(0, 0); // Scroll to top after navigation
      };
const group=[{path:'/',open:homeopen,id:1,Name:'Home',},{path:'/about',id:2,Name:'About Us',open:aboutopen},{path:'/service',id:3,Name:'Services',open:serviceopen},{id:4,Name:'Projects'},{path:'/contact',id:5,Name:'Contact Us',open:contactopen}];

  return (
    <div>
        <div className='footer-black'>
        <div className='total-footer'>
        <div className='logo'>
        <h1>SADE TECHNO SOLUTIONS</h1>
        <p className='company-desc'>IT SERVICES & TECH</p>
        <p>Specializing in web and mobile app<br/> development our company takes<br/> pride in crafting top-tier software<br/> products.<br/> </p>
<div className='full'>
<Icon icon="ri:facebook-fill" />
        <Icon icon="ri:twitter-line" />
        <Icon icon="flowbite:linkedin-solid" />
        <Icon icon="mdi:skype" />
        </div>
</div>
<div className='link'>
<h1>Quick Links</h1>
{group.map((group)=>(
    <div key={group.id}>
        <div onClick={group.open}  className='link-contain'>
            <Icon icon="iconamoon:arrow-right-2-duotone" />
            {group.Name}</div>
        </div>
    ))}
    </div>
        
        <div className='contact'>
            <h1>Contact Us</h1>
            <div className='add'>
            <Icon icon="fluent:location-28-regular" />
           <h4> Address:</h4>
            <p>125, Suitland Street, USA</p>
            </div>
            <div className='pho'>
            <Icon icon="material-symbols-light:wifi-calling-outline-rounded" />
           <h4> Phone:</h4>
            <p>+ 786 875 864 75</p>
            </div>
            <div className='email'>
            <Icon icon="mdi:email-outline" />
            <h4>E-Mail:</h4>
           <p> support@globex.com</p>
           </div>

        </div>
    </div>
    <br/><br/>

    <hr></hr>
    <div className='copy'>
   <p>  Copyright 2024. All Rights Reserved. Designed by Sade Techno Solutions</p>
   <div className='right'>

    
    </div>
    </div>
    </div>
    </div>
    
    
    
    

    
    
  )
}

export default Footer
