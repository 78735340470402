import React from 'react'
import { useParams } from 'react-router-dom'
import Dicated from './dicated'
import Nav from './navbar'

const Serdetail = ({data}) => {
    const {serviceId} = useParams()
    const detail = data.find((service)=>service.id === parseInt(serviceId))
    console.log(detail)
  return (
    
    <div className='space-contain'>
      <Nav/>
    <div className='detail-container'>
      <div className='left-container'>
        
         <Dicated/>
         </div>
         <div className='right-container'>
          <div className='divison'>
            <h2 style={{textDecoration:'none',color:'inherit'}}>{detail.heading}</h2>
          </div>
    
    
    <h3 style={{textDecoration:'none',color:'inherit'}}>{detail.Name}</h3>
    <p>{detail.page}</p>
    {detail.points&&(    <div>
    <p>{detail.points[0].head}</p>
    <p>{detail.points[1].head}</p>
    <p>{detail.points[2].head}</p>
    <p style={{fontWeight:700}}>{detail.parahead}</p>
    <p>{detail.approch[0].head}</p>
    <p>{detail.approch[1].head}</p>
    <p>{detail.approch[2].head}</p>
    </div>)}
    
    {detail.test&&(    <div>
    <p>{detail.test[0].head}</p>
    <p>{detail.test[1].head}</p>
    <p>{detail.test[2].head}</p>
    </div>)}
{detail.design&&(    <div>
    <p>{detail.design[0].head}</p>
    <p>{detail.design[1].head}</p>
    </div>)}
    <p style={{fontWeight:700}}>{detail.designhead}</p>
    {detail.uxui&&(    <div>
    <p>{detail.uxui[0].head}</p>
    <p>{detail.uxui[1].head}</p>
    </div>)}
    {detail.application&&( <div>
      <p>{detail.application[0].head}</p>
      <p>{detail.application[1].head}</p>
      <p>{detail.application[2].head}</p>
</div>)}
{detail.product&&( <div>
  <p>{detail.product[0].head}</p>
<p style={{fontWeight:700}}>{detail.saas}</p>
<p>{detail.develop[0].head}</p>
</div>)}
{detail.digital&&( <div>
      <p>{detail.digital[0].head}</p>
      <p>{detail.digital[1].head}</p>
      <p>{detail.digital[2].head}</p>
      <p>{detail.digital[3].head}</p>
</div>)}
    </div>
    </div>
    </div>
    
  )
}

export default Serdetail