import React from 'react'

const Tech = () => {
  return (

    <div>
        <div className='up-container'>
        <div className='down'>   
        <div className='tech'>
        <h3>TECHNOLOGY INDEX</h3>
        
        <h1>We Deliver Solutions<br/> with the Goal of Trusting<br/> Workships</h1>
        </div>
        <div className='web'>
        <div className='box-method'>
          <img className='bles'src='13.png'/>
            <h3>WEB</h3>
            </div>
            <div className='box-method'>
              <img className='bles-all' src='14.png'/>
            <h3>ANDROID</h3>
            </div>
            <div className='box-method'>
              <img className='bles-container'src='15.png'/>
           <h3>IOS</h3>
           </div>
           
           <div className='box-method'>
           <img className='bles' src='11.png' />
            <h3> WEARALABLES</h3>
            </div>
        </div>
        </div>
        </div>
        </div>

    
  )
}

export default Tech