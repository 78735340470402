import React from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'
import Footer from './footer'

const Contact = () => {
  return (
    <div>
       <div className='heading'>
       <h1 className='branch'>CONTACT US</h1>
        </div>
        <div className='boolean'>
        <div className='form-container'>
        <div className='width'>
            <div>
            <div className='form-heading'>
        <h4 ><span className='branch'>You Don't Hesitate To Contact With Us, Now <span className='source'>Say Hello......</span></span></h4>
        </div>
        <form action=''>
            <div className='total-container'>
            <div className='name'>
            <input className='whole'type='text' placeholder='Name'/>
            <Icon icon="wpf:name" />
            </div>
            <div className='name'>
                <input className='whole'type='text'placeholder='Email'/>
                <Icon icon="mdi:email-outline" />
            </div>
    
            <div className='name'>
                <input className='whole' type='no' placeholder='Phone no'/>
                <Icon icon="ph:phone-bold" />
            </div>
            <div className='name'>
                <input className='whole' type='text' placeholder='Service'/>
                <Icon icon="openmoji:department-store" />
            </div>
            <div className='msg'>
            <textarea rows='7' cols='50' name='message' placeholder='Your message here'></textarea>
            </div>
            </div>
            <div>
            <button className='video'>Send</button>
            </div>
        </form>
        </div>
        </div>
        </div>
        </div>

            </div>



  )
}

export default Contact