import React from 'react'
import About from './about'
import Nav from './navbar'
import Add from './add'
import Footer from './footer'

const Aboutpage = () => {
  return (
    <div>
        <Nav/>
        <About/>
        <div className='client'>
      <div className='head-para'>
   <h1> 330+</h1>
   <p>ACTIVE CLIENTS</p>
</div>
<div className='head-para'>
<h1>850+</h1>
<p>PROJECTS DONE</p>
</div>
<div className='head-para'>
<h1>25+</h1>
<p>TEAM ADVISORS</p>
</div>
<div className='head-para'>
<h1>10+</h1>
<p>GLORIOUS YEARS</p>
</div>
</div>
<div className='techno-container'>
<div className='sade-tech'>
<h1>< span className='branch'>OUR PROCESS</span></h1>
<p>At Sade Tech, our software development process is designed to deliver high-quality solutions efficiently and effectively. We start by understanding our clients' unique needs and challenges through detailed consultations. Our expert team then creates a strategic plan outlining clear milestones and timelines.During the design and development phase we utilize the latest technologies and best practices to ensure innovative and functional outcomes. Rigorous quality assurance testing is conducted at every stage to guarantee top-notch deliverables.</p>

    </div>
    
    </div>
    <div className='index'>
    <h3 className='branch'>TECHNOLOGY INDEX</h3>
    <h1 className='branch'>Real Time Monitoring Your Infrstracture<span className='branded'>
    Branded Degital Solutions</span></h1>
    </div>
    <div className='group-split'>
      <div className='split'>
        <img className='change-return'src='data.png'/>
      <h3>Data analytics</h3>
      </div>
      <div className='split'>
        <img className='re-change'src='web.png'/>
      <h3>Web Development</h3>
      </div>
      <div className='split'>
        <img className='re-change'src='des.png'/>
      <h3>UI/UX Design</h3>
      </div>
      <div className='split'>
        <img className='change-all'src='test.png'/>
      <h3>QA & Testing</h3>
      </div>
      
    </div>
    
    <div className='exp'>
    
    <h1 className='branch'>What We Actually Do</h1>
    </div>
    <div className='equal'>
    <div className='boot-contain'>
    <div className='change-con'>
      <img className='size-incre' src='html.png'/>
      <h3>HTML</h3>
      </div>
      <div className='change-con'>
        <img className='size-incre' src='tail.png'/>
      <h3>TAILWIND</h3>
      </div>
      <div className='change-con'>
        <img className='size-incre'src='css.png'/>
      <h3>CSS</h3>
      </div>
      <div className='change-con'>
        <img className='size-incre' src='react.png'/>
      <h3>REACT</h3>
      </div>
      <div className='change-con'>
        <img className='size-container' src='js.png'/>
      <h3>JAVASCRIPT</h3>
      </div>
    </div>
    
</div>
        
        <Footer/>
    </div>
  )
}

export default Aboutpage