import React from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'
import { Link } from 'react-router-dom'
import Service from './service'

const Dicated = () => {
    const page =[{id:1,Name:'IT Consultancy'},{id:'2',Name:'QA & Testing'},{id:3,Name:'UI/UX Design'},{id:4,Name:'Mobile App Development'},{id:5,Name:'Web Application Development'},{id:6,Name:'SaaS Products'},{id:7,Name:'Digital Marketing'},{id:8,Name:'IT Security Services'}]
  return (
    <div>
    <div className='dedicate'>
    {page.map((page)=> (
       <Link key={page.id} className='linked-page' to={`/service/${page.id}`}> <div className='team'>
            <div className='line'>
            <Icon icon="iconoir:arrow-right-circle-solid" />
            </div>
            <h3>{page.Name}</h3>    
    </div>
    </Link>
    ))
    
}
</div>
</div>
    
  )
}

export default Dicated