import React from 'react'
import { Icon } from '@iconify/react/dist/iconify.js';
import Footer from './footer';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <div>
    <div className='marker' ><h1 className='branch'>ABOUT COMPANY</h1></div>
      
<div className='para-image'>

      
        <div className='about'>
          
      
      
      <p>Specializing in web and mobile app development, our company takes pride in crafting top-tier software products. Our
adept team of developers possesses expertise across diverse technology stacks, enabling us to seamlessly integrate
pioneering design with state-of-the-art app development services. Be it consumer facing interfaces or B2B applications,
we excel in tailoring custom software for a multitude of digital platforms, encompassing mobile, web, and desktop
environments.<br/><br/>
Our commitment lies in crafting software that boasts full functionality, resilience, and scalability, built to endure the trials
of time. From technology consultation to development, rigorous testing, dependable support,and ongoing maintenance,
we ensure your software journey is guided by capable hands at every stage. Leveraging our established success, we
adeptly audit, enhance, and rejuvenate existing apps, modernizing legacy applications to align with contemporary needs and expectations.</p>

    </div>
    <div className='image-container'>
      <img className='contain-img' src='picture.jpg' />
     
      </div>
    
    </div>
     </div>  

    
    
    
    
    
  )
};

export default About