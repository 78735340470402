import React,{useState,useRef,useEffect} from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'
import { Link } from 'react-router-dom';
import Contact from './contact';
import Modal  from 'react-modal';


const Nav = () => {
  const [dropdown, setdropdown]=useState(false);
  
  const [showpopup, setshowpopup]=useState(false);
  const [clickpopup,setclickpopup]=useState(false);
  const [serach,setserach]=useState(false);

  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setserach(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  
      const subserach =()=>{
        setserach(true)
      }
      const menudropdown=()=>{
        setdropdown(!dropdown);
      }
      const down = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (down.current && !down.current.contains(event.target)) {
        setdropdown(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
      

      const showdropdown= ()=>{
        setshowpopup (true)
      }


        const closepopup = ()=> {
        setshowpopup(false)
        }

      const clickdropdown=()=>{
        setclickpopup(true)
      }
      const popupclose=()=>{
        setclickpopup(false)
      }
    

        const data = [{id:1,Name:'Home',path:'/'},{id:2,Name:'About Us',path:'/about'},{id:3,Name:'Services',path:'/service'},{id:4,Name:'Projects'},{id:5,Name:'Contact Us',path:'/contact'}]
  return (
    <div className='nav-all'>
    <div className='head'>
      <div className='msg-no'>
        <div className='msg'>
        <Icon icon="uil:comment-alt-message" />
        <p>info@exmel.com</p>
        </div>
        <div className='no'>
        <Icon icon="ic:outline-call" />
        <p>0123456789</p>
        </div>
        </div>
        <div className='icon'>
        
    <Link to={'/face'} > <Icon style={{color:'#106466'}}icon="ri:facebook-fill" /></Link>
        <Icon icon="ri:twitter-line" />
        <Icon icon="flowbite:linkedin-solid" />
        <Icon icon="mdi:skype" />
        </div>
</div>
    <nav>
      <div className='menu'>
        
        
        </div>
        <div className='nav-bar'>
      {data.map((data,index)=>(
        <div key={data.id}className='nav-bar'>
         <Link to={data.path} ><span className='arro underline'>{data.Name}</span></Link>
          </div>
      )
      )}
      </div>
  <div className='all-items'>
  
    <div className='sharp'>
       {serach&&(
      <input ref={dropdownRef} type='text'/>
       )}
      </div>
      <Icon style={{cursor:'pointer'}} onClick={subserach}icon="ic:sharp-search" />
          

          <div className='submenu'>

      <Icon onClick={menudropdown}style={{cursor:'pointer'}} icon="ion:menu-sharp" />
      
      {dropdown&&(

         <div ref={down}className='local-all'>
        <div className='url-container'>
             <Link to={'/'}style={{textDecoration:'none',color:'black',height:'40px'}}>
             <p> HOME</p> </Link>
              
            </div>
            <div className='url-container'>
            <Link to={'/about'}style={{textDecoration:'none',color:'black',height:'40px'}}>
             <p> ABOUT </p></Link>
            </div>
            <div className='url-container'>
            <Link to={'/service'} style={{textDecoration:'none',color:'black',height:'40px'}}> 
            <p>SERVICE</p></Link>
            </div>
            <div className='url-container'>
             <p>PROJECT</p>
             </div>
            <div className='url-container'>
           <Link to={'/contact'}style={{textDecoration:'none',color:'black',height:'40px'}} > 
           <p>CONTACT US</p> 
           </Link>
           
            </div>
            </div>
  )}
  </div>
  <div className='color'>
          
      <button onClick={showdropdown} className='video'>Free Consulting</button>
      </div>
      </div>
      
      
    <Modal isOpen={showpopup} onRequestClose={closepopup}
    style={{    overlay: {
      zIndex: 9999
    },
        content: {
     
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          backgroundColor:'transparent',
          transform: 'translate(-50%, -50%)',
          width: '70%',
          height: '95%',
          overflowY: 'auto',
          border:'none'
        },}}>
          {showpopup&&(
            <div className='popup'>
            <div className='place'>
           
           </div>
           
           <div>
             <div  className='tag-container'>
              <span></span>
             <span className='branch'> <h2><span className='branch'>Join Us To Get IT Free Consultations</span> </h2></span>
            <Icon onClick={closepopup}className='close-container' icon="carbon:close-filled" />
            </div>
   
            </div>
            <div className='adjust'>
            <div className='scrol'>
            <div className='box'>
                <div className='total-box'>
                <div className='name-add'>
                    <div className='message-box'>
                <lable>Your Name*</lable>
                <input className='increse'type='text'/>
                </div>
                <div className='message-box'>      
                    <lable>Email Address*</lable>
            <input className='increse'type='text' />
            </div>
            </div>
            </div>
            <div className='web-no'>
                <div  className='message-box'>
            <lable>Phone number*</lable>
            <input className='increse'type='number'/>
            </div>
            <div className='message-box'>
            <lable>Website</lable>
            <input className='increse' type='text'/>
            </div>
            </div>
            <div className='message-box'>
            <lable>Your message*</lable>
            <textarea rows='4'cols='59' />
            </div>
            </div>
            <div className='send-now'>
            <button className='video'>Send Now</button>
            </div>
            </div>
            <div className='locate'>
            <div className='location-contain'>
            <Icon icon="tabler:message" />
            <p>contacto@sadetech</p>
          </div>
          <hr></hr>
            <div className='location-contain'>
            <Icon icon="carbon:location" />
            <p>Región Metropolitana, Santiago de Chile.</p>
            </div>
            <hr></hr>
            <div className='face'>
            <Icon icon="lucide:facebook" />
            <Icon icon="hugeicons:twitter" />
            <Icon icon="flowbite:linkedin-solid" />
            <Icon icon="mdi:skype" />
            </div>
            </div>
            </div> 
            </div>
      )} 
  
  </Modal>
  
         </nav>
         
  </div>
  
 )
}

export default Nav